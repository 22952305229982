import axios from 'axios';
import {ActionResult} from '@/models/ActionResult';
import {Guarantee} from '@/views/guarantee/models/Guarantee';
import {GuaranteeDetail} from '@/views/guarantee/models/GuaranteeDetail';
import {SearchResult} from '@/models/SearchResult';
import {ProductOrderSearchResult} from '@/views/guarantee/models/ProductOrderSearchResult';
import moment from 'moment';
import {SettingColumnSelect} from '@/models/settingColumn/SettingColumnResult';
import {QueryLoadOption} from '@/models/QueryLoadOptions';
import {GuaranteePrintResult} from '@/views/guarantee/models/GuaranteePrintResult';
import {FileResult} from '@/components/upload-file/FileResult';

export class GuaranteeService {
    url = `${process.env.VUE_APP_GATEWAY_URL}guarantee/api/v1/guarantees`;

    deleteGuaranteeDetail(guaranteeId: string, guaranteeDetailId: string): Promise<ActionResult> {
        return axios.delete(`${this.url}/${guaranteeId}/details/${guaranteeDetailId}`);
    }

    insertGuaranteeDetail(guaranteeId: string, guaranteeDetail: GuaranteeDetail[]): Promise<ActionResult> {
        return axios.post(`${this.url}/${guaranteeId}/details`, guaranteeDetail);
    }

    updateGuaranteeDetail(guaranteeId: string, guaranteeDetailId: string, guaranteeDetail: GuaranteeDetail): Promise<ActionResult<string>> {
        return axios.put(`${this.url}/${guaranteeId}/details/${guaranteeDetailId}`, guaranteeDetail);
    }

    insertGuarantee(guarantee: Guarantee): Promise<ActionResult<Guarantee>> {
        guarantee.attachments = guarantee.attachments ? guarantee.attachments.map((file: FileResult) => {
            file.createTime = moment(file.createTime, 'DD/MM/YYYY hh:mm:ss').format();
            return file;
        }) : [];
        return axios.post(`${this.url}`, guarantee);
    }

    updateGuarantee(id: string, guarantee: Guarantee): Promise<ActionResult<string>> {
        guarantee.attachments = guarantee.attachments.map((file: FileResult) => {
            file.createTime = moment(file.createTime, 'DD/MM/YYYY hh:mm:ss').format();
            return file;
        })
        return axios.put(`${this.url}/${id}`, guarantee);
    }

    deleteGuarantee(id: string): Promise<ActionResult> {
        return axios.delete(`${this.url}/${id}`);
    }

    searchOrderProduct(orderCode?: string, customerCode?: string, productCode?: string, fromDate?: string,
                       toDate?: string, page = 1, pageSize = 10): Promise<SearchResult<ProductOrderSearchResult>> {
        return axios.get(`${process.env.VUE_APP_GATEWAY_URL}order/api/v1/orders/search-products`, {
            params: {
                orderCode: orderCode ? orderCode : '',
                customerCode: customerCode ? customerCode : '',
                productCode: productCode ? productCode : '',
                fromDate: fromDate ? moment(fromDate, 'DD-MM-YYYY').format() : null,
                toDate: toDate ? moment(toDate, 'DD-MM-YYYY').format() : null,
                page,
                pageSize,
            },
        })
    }

    getUserConfig(): Promise<SettingColumnSelect[]> {
        return axios.get(`${this.url}/configs`);
    }

    getUserConfigDetail(): Promise<SettingColumnSelect[]> {
        return axios.get(`${this.url}/config-details`);
    }

    search(loadOptions: QueryLoadOption): Promise<SearchResult<Guarantee>> {
        return axios.post(`${this.url}/search`, loadOptions);
    }

    searchDetail(loadOptions: QueryLoadOption): Promise<SearchResult<GuaranteeDetail>> {
        return axios.post(`${this.url}/search-detail`, loadOptions);
    }

    getGuaranteeDetail(id: string): Promise<ActionResult<Guarantee>> {
        return axios.get(`${this.url}/${id}`);
    }

    updateGuaranteeDetailDeliveryDate(id: string, deliveryDate: string | null): Promise<ActionResult> {
        return axios.post(`${this.url}/${id}/delivery-date`, {
            deliveryDate,
        });
    }

    getListStatus(): Promise<any> {
        return axios.get(`${this.url}/status`);
    }

    updateStatus(guaranteeId: string, status: number): Promise<any> {
        return axios.post(`${this.url}/${guaranteeId}/status/${status}`);
    }

    getPrintDetail(guaranteeIds: string[]): Promise<GuaranteePrintResult[]> {
        return axios.post(`${this.url}/print`, guaranteeIds);
    }

    deleteAttachment(guaranteeId: string, attachmentIds: string[]): Promise<ActionResult> {
        return axios.post(`${this.url}/${guaranteeId}/attachments`, {
            attachmentIds: attachmentIds,
        });
    }

    async exportExcel(loadOptions: QueryLoadOption) {
        let result = (await axios.post(`${this.url}/excel`,
            loadOptions, {responseType: 'blob'})) as any;
        const url = window.URL.createObjectURL(new Blob([result]))
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download',
            `INKA CRM@Danh sach chi tiet phieu bao hanh.xlsx`
        ); //or any other extension

        document.body.appendChild(link);
        link.click();
        return true;
    }
}
