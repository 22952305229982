














import { Component, Ref, Vue } from "vue-property-decorator";
import CustomerSuggestion from "@/components/suggestion/customer-suggestion/CustomerSuggestion.vue";
import { SuggestionModel } from "@/components/suggestion/base-suggestion/SuggestionModel";

@Component({
    components: {
        CustomerSuggestion
    }
})
export default class CustomerCellEditor extends Vue {
    @Ref('customerSuggestion') customerSuggestion!: any;
    params: any = null;
    value: any = null;
    customerType = null;

    buttonText = '';

    get selectedCustomer() {
        const data = this.params.data;
        const text = data[this.params.textField];
        console.log(text);
        return this.params ? [new SuggestionModel(this.value, text ? text : '', '', '', true, this.params.data)]
            : [];
    }

    created() {
        this.value = this.params.value;
        this.buttonText = this.params.buttonText;
        this.customerType = this.params.customerType;
    }

    mounted() {
        setTimeout(() => {
            this.customerSuggestion.$el.querySelector('input').focus()
        }, 100);
    }

    getValue(): any {
        return this.value;
    }

    onCustomerSelected(customers: SuggestionModel[]) {
        if (!customers || customers.length == 0) {
            return;
        }
        const customer = customers[0];
        this.value = customer.id;
        if (typeof (this.params.valueChanged) === "function") {
            this.params.valueChanged(this.params.data, customer);
        }

    }

    onCustomerSelectedRemove(customers: SuggestionModel[]) {
        this.value = null;
        if (typeof (this.params.valueChanged) === "function") {
            this.params.valueChanged(this.params.data, null);
        }
    }
}
