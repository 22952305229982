












































































































import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import {Component, Ref, Vue, Watch} from "vue-property-decorator";
import FilterList from "@/components/filter/FilterList.vue";
import SettingColumns from "@/components/SettingColumns.vue";
import SelectPageSize from "@/components/select-page-size/select-page-size.vue";
import {AgGridVue} from "ag-grid-vue";
import {FilterAttributeResult} from "@/models/attribute/FilterAttributeResult";
import {QueryLoadOption, SortingInfo} from "@/models/QueryLoadOptions";
import {SettingColumnResult, SettingColumnSelect} from "@/models/settingColumn/SettingColumnResult";
import DropdownCellRenderer from "@/components/ag-grid/DropdownCellRenderer.vue";
import {FilterStateResult} from "@/models/filter/filter-state-result";
import filter from "lodash/filter";
import _filter from "lodash/filter";
import _each from "lodash/each";
import _find from "lodash/find";
import map from "lodash/map";
import {ActionResult} from "@/models/ActionResult";
import CellReceiptStatus from "@/views/cash-book/receipt/cell-render/CellReceiptStatus.vue";
import {SearchResult} from "@/models/SearchResult";
import CustomerCellComponent from "@/components/ag-grid/CustomerCellComponent.vue";
import CellReceiptCertificateCode from "@/views/cash-book/receipt/cell-render/CellReceiptCertificateCode.vue";
import {GuaranteeService} from "@/views/guarantee/GuaranteeService";
import {Guarantee} from "@/views/guarantee/models/Guarantee";
import QuantityCellRenderer from "@/views/order/order-edit/cell-renderer/QuantityCellRenderer.vue";
import CustomerCellEditor from "@/views/order/order-edit/cell-editor/CustomerCellEditor.vue";
import {AttributeType, DataType, LogicType, OperatorType} from "@/constants/Attribute";
import moment from "moment";
import CellSelectOptionEditor from "@/components/ag-grid/cell-select-option-editor/CellSelectOptionEditor.vue";
import {Suggestion} from "@/models/Suggestion";
import GuaranteePrint from "@/views/guarantee/GuaranteePrint.vue";

@Component({
    name: "ReceiptList",
    components: {
        FilterList,
        AgGridVue,
        SelectPageSize,
        SettingColumns,
        GuaranteePrint,
    },
})

export default class GuaranteeList extends Vue {
    @Ref("agGridTable") agGridTable!: any;
    @Ref("filter") filter!: any;
    @Ref("selectPageSize") selectPageSize!: any;
    @Ref("settingColumnsRef") settingColumnsRef!: any;
    @Ref("guaranteePrint") guaranteePrint!: any;
    moduleName: string;
    pageName: string;
    searchQuery = "";
    gridOptions: any = {};
    maxPageNumbers = 7;
    gridApi: any = null;
    defaultColDef = {
        sortable: false,
        editable: false,
        resizable: true,
        suppressMenu: true,
    };
    columnDefs: any = null;
    totalPages = 0;
    totalRows = 0;
    frameworkComponents: any = null;
    guarantees: Guarantee[] = [];
    currentPage = 1;
    selectedIds: any = [];
    attributeOptions: FilterAttributeResult[] = [];

    // togge sidebar
    wasSidebarOpen: any = null;

    //setting column
    defaultSettingColumns: any[] = [];
    settingColumnConfig: SettingColumnResult = new SettingColumnResult();

    // Search loadOption
    loadOptions: QueryLoadOption = new QueryLoadOption();
    attributeSearchByKeywords = ["code", "referenceCode"];
    selectFieldRequired: string[] = ["id", "customerId", "referenceType"];
    listCustomTab: any[] = [];
    selectedGuarantee: Guarantee;
    onRowSelected: any = null;
    listStatus = [];

    constructor() {
        super();
        this.loadOptions.sort = [new SortingInfo("createTime")];
    }

    beforeMount() {
        this.listCustomTab = [
            // {id: 'customerReport', title: this.$t('Report'), filter: null},
            {
                id: "detail",
                title: "Danh sách chi tiết",
            },
        ];

        this.frameworkComponents = {
            "dropdownCellRenderer": DropdownCellRenderer,
            "cellReceiptStatus": CellReceiptStatus,
            "customerCellComponent": CustomerCellComponent,
            "cellReceiptCertificateCode": CellReceiptCertificateCode,
            "customerCellEditor": CustomerCellEditor,
            "cellSelectOptionEditor": CellSelectOptionEditor,
        };

        this.gridOptions = {
            localeText: {noRowsToShow: "Không tìm thấy phiếu yêu cầu."},
        };
        this.onRowSelected = (params: any) => {
            let data: any = params.data;

            if (params.node.selected) {
                this.selectedIds.push(data.id);
            } else {
                this.selectedIds = _filter(this.selectedIds, (item: any) => {
                    return item !== data.id;
                });
            }
        };
        this.initial();
    }

    mounted() {
        this.gridApi = this.gridOptions.api;
        this.gridOptions.suppressRowTransform = true;

        //this.gridApi.sizeColumnsToFit();
        /* =================================================================
          NOTE:
          Header is not aligned properly in RTL version of agGrid table.
          However, we given fix to this issue. If you want more robust solution please contact them at gitHub
        ================================================================= */
        if (this.$vs.rtl) {
            const header = this.agGridTable.$el.querySelector(".ag-header-container");
            header.style.left = "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
        }

        this.wasSidebarOpen = this.$store.state.reduceButton;
        this.$store.commit("TOGGLE_REDUCE_BUTTON", true);
    }

    beforeDestroy() {
        if (!this.wasSidebarOpen) this.$store.commit("TOGGLE_REDUCE_BUTTON", false)
    }

    get paginationPageSize() {
        if (this.gridApi) return this.gridApi.paginationGetPageSize();
        else return 20;
    }

    get rowData() {
        return this.guarantees;
    }

    get isEnablePrintButton() {
        return this.selectedIds.length > 0;
    }

    @Watch("currentPage")
    onCurrentPageChange(value: number, oldValue: number) {
        this.search(false);
    }

    initial() {
        /// Lấy danh mục
        new GuaranteeService().getListStatus().then((result: any) => {
            this.listStatus = result;
            _each(this.listStatus, (item: any) => {
                item.id = item.value;
            })

            if (this.listStatus) {
                this.renderAttributeOptions();
                this.getColumnDefaultApi();
                new GuaranteeService().getUserConfig()
                    .then((result: SettingColumnSelect[]) => {
                        const selectionColumn = {
                            editable: false,
                            field: "selection",
                            nameDisplay: "",
                            isRequired: false,
                            isSelected: true,
                            name: "Hành động",
                            sortable: false,
                        };

                        result.unshift(selectionColumn);
                        this.settingColumnConfig.columnConfigData = result;
                        this.settingColumnsRef.agGridRefresh(this.settingColumnConfig);

                        this.loadOptions.select = map(filter(this.settingColumnConfig.columnConfigData, (item: SettingColumnSelect) => {
                            return item.isSelected;
                        }), "field");

                        const filterState: FilterStateResult = this.$store.getters["filter/getFilter"](this.$route.fullPath);
                        if (!filterState || filterState.searchValue !== null) {
                            this.search();
                        }

                        if (this.gridApi) {
                            this.gridApi.refreshCells();
                        }
                    });
            }
        })
    }

    onCellValueChanged(params: any) {
        const colId = params.column.getId();
        let oldValue: any = params.oldValue;
        let newValue = params.newValue;
        if (oldValue) {
            oldValue = oldValue.toString().trim();
        }
        if (newValue) {
            newValue = newValue.trim();
        }
        if (oldValue === newValue) {
            return;
        }

        const data = params.data;
        if (colId === "status") {
            const statusInfo: any = this.listStatus.find((status: Suggestion) => {
                return status.name === newValue;
            });
            if (statusInfo) {
                new GuaranteeService().updateStatus(data.id, statusInfo.id);
            }
        }
    }

    onSortChange(e: any) {
        const columnSort = e.api.getSortModel();
        if (columnSort && columnSort[0]) {
            this.loadOptions.sort = [new SortingInfo(columnSort[0].colId, columnSort[0].sort === "desc")];

            this.setFilterState(this.currentPage, this.paginationPageSize, this.loadOptions);
            this.search(false);
        }
    }

    onRowDragEnd(e: any) {
        //this.gridApi.resetRowHeights()
    }

    showDetail(guarantee: Guarantee) {
        if (guarantee) {
            this.$router.push({name: "guaranteeDetail", params: {id: guarantee.id}});
        }
    }

    edit(guarantee: Guarantee) {
        if (guarantee) {
            this.$router.push({name: "guaranteeEdit", params: {id: guarantee.id}});
        }
    }

    deleteGuarantee(guarantee: Guarantee) {
        this.selectedGuarantee = guarantee;
        this.$vs.dialog({
            type: "confirm",
            color: "danger",
            title: this.$t("ConfirmDelete"),
            acceptText: this.$t("Accept"),
            cancelText: this.$t("Cancel"),
            text: this.$t("ConfirmDeleteText", {data: guarantee.name}),
            accept: this.acceptDelete,
        })
    }

    acceptDelete() {
        if (this.selectedGuarantee) {
            new GuaranteeService().deleteGuarantee(this.selectedGuarantee.id).then((result: ActionResult) => {
                this.$vs.notify({
                    color: result.code <= 0 ? "danger" : "success",
                    title: "Thông báo",
                    text: result.message,
                })

                if (result.code > 0) {
                    this.search();
                }
            }).catch((err: any) => {
                this.$vs.notify({
                    color: "danger",
                    title: "Thông báo",
                    text: err.message,
                })
            })
        }
    }

    changePageSize(pageSize: number) {
        this.gridApi.paginationSetPageSize(pageSize);
        this.currentPage = 1;

        this.setFilterState(this.currentPage, pageSize, this.loadOptions);
        this.search();
    }

    beginSearch(page: number) {
        if (this.searchQuery == undefined || this.searchQuery.length < 2) {
            return;
        }
        this.search();
    }

    searchByFilter(filterQuery: string) {
        this.loadOptions.filterQuery = filterQuery;
        this.currentPage = 1;
        this.search();
    }

    getRowStyle(params: any) {
        if (params.node.rowPinned) {
            return {"font-weight": "bold"};
        }
    }

    // Setting clumn
    openSettingColumnModal() {
        this.settingColumnsRef.openSettingColumnModal();
    }

    // Cập nhật column
    settingColumnUpdated(config: SettingColumnResult) {
        this.settingColumnConfig = config;
        this.getFieldSelectFromResult();
        this.search();
    }

    exportExcel() {
    }

    // Show bộ lọc
    showModalFilter(value: FilterAttributeResult[]) {
        if (!value || value.length === 0) {
            this.renderAttributeOptions();
        }
    }

    print() {
        if (this.selectedIds.length == 0) {
            this.$vs.notify({
                color: "danger",
                title: "Thông báo",
                text: "Vui lòng chọn ít nhất 1 phiếu.",
            });
            return;
        }
        this.guaranteePrint.print(this.selectedIds);
    }

    selectDetail(value: any) {
        this.$router.push({name: "guaranteeDetailList"});
    }

    private openLoading(value: boolean) {
        if (value) {
            this.$vs.loading();
        } else {
            this.$vs.loading.close();
        }
    }

    // Tìm kiếm
    private search(isShowDetail = false) {
        this.openLoading(true);
        const filterState: FilterStateResult = this.$store.getters["filter/getFilter"](this.$route.fullPath);
        if (filterState) {
            this.loadOptions.filterQuery = filterState.loadOption.filterQuery;
            this.gridApi.paginationSetPageSize(filterState.pageSize);
        }

        this.loadOptions.skip = (this.currentPage - 1) * this.paginationPageSize;
        this.loadOptions.take = this.paginationPageSize;

        new GuaranteeService()
            .search(this.loadOptions)
            .then((result: SearchResult<Guarantee>) => {
                this.openLoading(false);
                let listData: any = [];
                if (isShowDetail) {
                    _each(result.items, (item: any) => {
                        if (item.details) {
                            _each(item.details, detail => {
                                listData.push(detail);
                            })
                        } else {
                            listData.push(item);
                        }
                    })

                    this.guarantees = listData;
                } else {
                    this.guarantees = result.items;
                }

                this.setFilterState(this.currentPage, this.paginationPageSize);
                this.totalRows = result.totalRows;
                setTimeout(() => {
                    this.setTotalPage(this.totalRows);
                }, 200)
            })
            .catch(() => this.openLoading(false))
            .finally(() => this.openLoading(false));
    }

    /// Set total Page
    private setTotalPage(totalRows: number) {
        this.totalRows = totalRows;
        if (totalRows == 0) {
            this.totalPages = 0;
        } else if (totalRows <= this.paginationPageSize) {
            this.totalPages = 1;
        } else {
            this.totalPages = Math.ceil((totalRows / this.paginationPageSize));
        }
    }

    /// Tạo các thuộc tính tìm kiếm
    private renderAttributeOptions() {
        this.attributeOptions = [
            new FilterAttributeResult("Code", "Mã phiếu bảo hành", "", "",
                false, AttributeType.Input, [], [], OperatorType.Equal,
                "", LogicType.And, DataType.String),
            new FilterAttributeResult("Name", "Tên phiếu bảo hành", "", "",
                false, AttributeType.Input, [], [], OperatorType.Equal,
                "", LogicType.And, DataType.String),
            new FilterAttributeResult("Details.ProviderId", "Nhà cung cấp", "", "",
                false, AttributeType.DropdownList, [], [], OperatorType.Equal,
                `${process.env.VUE_APP_CUSTOMER_URL}api/v1/customers/suggestions`, LogicType.And, DataType.String),
            new FilterAttributeResult("CustomerId", "Khách hàng", "", "",
                false, AttributeType.DropdownList, [], [], OperatorType.Equal,
                `${process.env.VUE_APP_CUSTOMER_URL}api/v1/customers/suggestions`, LogicType.And, DataType.String),
            new FilterAttributeResult("TotalAmount", "Tổng tiền", "", "",
                false, AttributeType.NumberInput, [], [], OperatorType.Equal,
                "", LogicType.And, DataType.Decimal),
            new FilterAttributeResult("ResponsibleFullName", "Người phụ trách", "", "",
                false, AttributeType.DropdownList, [], [], OperatorType.Equal,
                `${process.env.VUE_APP_CORE_URL}api/v1/users/suggestions`, LogicType.And, DataType.String),
            new FilterAttributeResult("Status", "Trạng thái", "", "",
                false, AttributeType.DropdownList, [], this.listStatus, OperatorType.Equal,
                "", LogicType.And, DataType.String),
            new FilterAttributeResult("CreatorId", "Người tạo", "", "",
                false, AttributeType.DropdownList, [], [], OperatorType.Equal,
                `${process.env.VUE_APP_CORE_URL}api/v1/users/suggestions`, LogicType.And, DataType.String),
            new FilterAttributeResult("CreateTime", "Ngày tạo", "", "",
                false, AttributeType.DateTime, [], [], OperatorType.Equal,
                "", LogicType.And, DataType.DateTime),
        ];
    }

    private getColumnDefaultApi() {
        this.columnDefs = [
            {
                headerName: "",
                field: "actions",
                filter: false,
                width: 50,
                pinned: "left",
                suppressSizeToFit: true,
                cellRenderer: "dropdownCellRenderer",
                headerClass: "items-center",
                cellClass: "flex content-center",
                cellRendererParams: {
                    source: [
                        {
                            icon: "AlignJustifyIcon",
                            text: "Chi tiết",
                            svgClass: "fill-current text-success text-xs",
                            onClick: this.showDetail,
                        },
                        {
                            icon: "EditIcon",
                            text: "Chỉnh sửa",
                            svgClass: "text-success text-xs",
                            onClick: this.edit,
                        },
                        {
                            icon: "Trash2Icon",
                            text: "Xóa",
                            svgClass: "text-danger text-xs",
                            onClick: this.deleteGuarantee,
                        },
                    ],
                },
            },
            {
                headerName: "",
                field: "selection",
                filter: false,
                checkboxSelection: true,
                headerCheckboxSelectionFilteredOnly: false,
                headerCheckboxSelection: true,
                width: 50,
                pinned: "left",
                suppressSizeToFit: true,
            },
            {
                headerName: "Mã",
                field: "code",
                filter: true,
                sortable: false,
                width: 180,
                pinned: "left",
                suppressSizeToFit: true,
                editable: false,
            },
            {
                headerName: "Mã tham chiếu",
                field: "referenceCode",
                filter: true,
                sortable: false,
                width: 180,
                suppressSizeToFit: true,
                editable: false,
            },
            {
                headerName: "Tên yêu cầu",
                field: "name",
                filter: true,
                sortable: false,
                width: 200,
                editable: false,
                pinned: "left",
                suppressSizeToFit: true,
            },
            {
                headerName: "Tên khách hàng",
                field: "customerName",
                filter: true,
                sortable: false,
                width: 150,
                editable: false,
            },
            {
                headerName: "Số điện thoại khách hàng",
                field: "customerPhoneNumber",
                filter: true,
                sortable: false,
                width: 180,
                editable: false,
            },
            {
                headerName: "Tổng tiền",
                field: "totalAmount",
                filter: true,
                sortable: false,
                width: 180,
                editable: false,
                cellClass: "text-right",
                headerClass: "number-cell",
                cellRendererFramework: QuantityCellRenderer,
            },
            {
                headerName: "Người phụ trách",
                field: "responsibleFullName",
                filter: true,
                sortable: false,
                width: 200,
                editable: false,
            },
            {
                headerName: "Trạng thái",
                field: "status",
                filter: true,
                sortable: false,
                width: 200,
                cellRenderer: (params: any) => {
                    const data = params.data;
                    if ((typeof data.status) === "string") {
                        return `<a class="con-edit-td cursor-pointer">${data.status}</a>`
                    } else {
                        const info = _find(this.listStatus, (item: any) => {
                            return item.id === data.status;
                        })
                        if (info) {
                            return `<a class="con-edit-td cursor-pointer">${info.name}</a>`
                        } else {
                            return "";
                        }
                    }
                },
                editable: true,
                cellEditor: "cellSelectOptionEditor",
                cellEditorParams: {
                    source: this.listStatus.map((item: Suggestion) => {
                        return item.name;
                    }),
                },
            },
            {
                headerName: "Người tạo",
                field: "creatorFullName",
                filter: true,
                sortable: false,
                width: 200,
                editable: false,
            },
            {
                headerName: "Ngày tạo",
                field: "createTime",
                filter: false,
                sortable: false,
                width: 200,
                editable: false,
                valueFormatter: (params: any) => {
                    return params.data.createTime ? moment(params.data.createTime).format("DD-MM-YYYY HH:mm") : "";
                },
            },
            {
                headerName: "Nhà cung cấp",
                field: "details.ProviderName",
                filter: true,
                sortable: false,
                width: 180,
                editable: false,
            },
            {
                headerName: "Sản phẩm",
                field: "details.ProductName",
                filter: true,
                sortable: false,
                width: 180,
                editable: false,
            },
        ];
    }

    private getFieldSelectFromResult() {
        const columnSelect = filter(this.settingColumnConfig.columnConfigData, (item: SettingColumnSelect) => {
            return item.isSelected;
        });

        const fieldSelect = map(columnSelect,
            (item: SettingColumnSelect) => {
                return item.field
            });

        this.loadOptions.select = this.selectFieldRequired.concat(fieldSelect).filter((idx: string) => {
            return idx !== "actions" && idx !== "selection";
        });

        if (!fieldSelect || fieldSelect.length < 5) {
            this.gridApi.sizeColumnsToFit();
        }
    }

    private setFilterState(currentPage: number, pageSize: number, loadOption?: QueryLoadOption) {
        let filterState: FilterStateResult = this.$store.getters["filter/getFilter"](this.$route.fullPath);
        if (!filterState) {
            filterState = new FilterStateResult();
        }

        filterState.pageSize = pageSize;
        filterState.currentPage = currentPage;
        filterState.loadOption = loadOption ? loadOption : filterState.loadOption;
        filterState.module = this.$route.fullPath;
        filterState.attributeOptions = this.attributeOptions;

        // Cập nhật lại filter state
        this.$store.dispatch("filter/setFilterState", filterState);
    }
}
