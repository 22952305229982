



















































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import Printd from 'printd'
import { OrderService } from "@/views/order/OrderService";
import { OrderPrintResult } from "@/views/order/order-print/OrderPrintResult";
import { ActionResult } from "@/models/ActionResult";
import { CustomerType } from "@/constants/Customer";
import { Money, VMoney } from 'v-money'
import moment from "moment";
import { GuaranteeService } from "@/views/guarantee/GuaranteeService";
import { GuaranteePrintResult } from "@/views/guarantee/models/GuaranteePrintResult";

@Component({
    components: {
        Money
    }
})
export default class GuaranteePrint extends Vue {
    isLoading = false;
    listGuarantee: any = [];
    moneyOptions = {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false
    };
    quantityOptions = {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false
    }
    css = `
        @page { size: auto;  margin: 5mm; }

        .print-page {
            font-size: 13px;
        }

        .w100pc {
            width: 100%;
        }

        .text-right {
            text-align: right;
        }

        .note {
            font-style: italic;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        table {
            border-collapse: collapse;
            width: 100%;
        }

        table th, table, td {
            border: 1px solid #ddd;
            padding: 5px;
        }

        table.no-border, table.no-border tr td, table.no-border tr th {
            border: none !important;
        }

        .company-name {
            text-transform: uppercase;
            font-size: 16px;
        }

        h5 {
            margin-top: 10px;
            margin-bottom: 5px;
        }

        .text-center {
            text-align: center;
        }

        .bold {
            font-weight: bold;
        }

        .text-center {
            class="text-center"
         }

        input.money {
            font-family: "Montserrat", Helvetica, Arial, sans-serif;
            border: none;
            text-align: right;
            background: transparent;
            font-size: 14px;
        }

        .header{
            background-color: #999;
            text-transform: uppercase; text-align: center;

        }

        @media print {
          footer {page-break-after: always;}
        }
    `;

    print(guaranteeIds: string[]) {
        if (!guaranteeIds || guaranteeIds.length === 0) {
            this.$vs.notify({
                title: "Thông báo",
                text: "Vui lòng chọn phiếu bảo hành cần in",
                color: "warning"
            });
            return;
        }
        const guaranteeService = new GuaranteeService();
        this.isLoading = true;
        this.listGuarantee = [];
        guaranteeService.getPrintDetail(guaranteeIds)
            .then((result: GuaranteePrintResult[]) => {
                this.isLoading = false;
                if (result != null && result.length > 0) {
                    result.forEach((item: GuaranteePrintResult) => {
                        if (item) {
                            item.createTime = item.createTime ? moment(item.createTime).format('DD/MM/YYYY hh:mm') : '';
                            this.listGuarantee.push(item);
                        }
                    });
                    setTimeout(() => {
                        const printer = new Printd();
                        const guaranteePrintElement: any = this.$refs.guaranteePrintPage;
                        printer.print(guaranteePrintElement, [
                            this.css
                        ]);
                    }, 500);
                }
            })
            .catch(() => this.isLoading = false);
    }
}
